/* eslint-disable @typescript-eslint/no-explicit-any */

export const sleep = (ms: number): Promise<typeof setTimeout> => new Promise(res => setTimeout(res, ms))

export const doSort = (by: string, data: Array<any>, rev = false): Array<any> => {
  return data.sort((a: any, b: any): number => {
    if (a[by] > b[by]) return !rev ? 1 : -1;
    if (a[by] < b[by]) return !rev ? -1 : 1;
    return 0;
  });
}
