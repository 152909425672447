import React, { useState, useEffect } from "react";
import Login from "./pages/login";
import Register from "./pages/register";
import Dashboard from "./pages/dashboard";
import Meetings from "./pages/meetings";
import MeetingReport from "./pages/meeting-report";
import NewMeeting from "./pages/new-meeting";
import Teams from "./pages/teams";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink
} from "react-router-dom";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, getUserData } from "./utils/fb";
import Loading from "./pages/parts/loading";
import './styles/app.sass'
import Team from "./pages/team";
import Recommendations from "./pages/recommendations";
import RecoverPassword from "./pages/recoverPassword";
import notificationIcon from './img/logo-w.svg';
import Profile from "./pages/profile";
import { __DEV__ } from "./utils/_env";
import BetaSignUp from "./pages/betaSignUp";


export default function App(): React.ReactElement {

  const [loading, setLoading] = useState(true as any);
  const [user, setUser] = useState(null as any);
  const [data, setData] = useState(null as any);

  useEffect(() => {
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        let d: any = await getUserData(u.uid);
        if (!d) {
          d = {
            teams: [],
            meetings: [],
            notifications: [],
            trends: []
          }
        }
        setData(d);
        setUser(u);
      }
      setLoading(false);
      const title = window.location.pathname
      document.title = `Fiill ${title.replace('/', ' | ')}`;
    });
  }, []);

  const beta = !__DEV__ && !user && window.location.pathname !== '/login';
  return (
    <Router>
      <div className="main main-app">
        <Loading show={loading} home={true} />
        {
          beta ?
            <BetaSignUp />
            :
            <Switch>
              <Route path="/login">
                {user ? <Redirect to="/dashboard" /> : <Login />}
              </Route>
              <Route path="/register">
                {user ? <Redirect to="/dashboard" /> : <Register />}
              </Route>
              <Route path="/change-password"> <RecoverPassword />          </Route>
              <div className="grid">
                <nav>
                  <div className="logo grid-small">
                    <NavLink to="/" className="logo">
                      <img src={notificationIcon} alt="Logo Fiill" />
                    </NavLink>
                  </div>
                  <ul>
                    <li>
                      <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/meetings">Meetings</NavLink>
                    </li>
                    <li>
                      <ul>
                        <li>
                          <NavLink activeClassName="active" to="/new-meeting">  New meeting</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/recommendations">Recommendations</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/teams">Teams</NavLink>
                    </li>
                    <li>
                      <ul>

                        {/* <li>
                      <NavLink activeClassName="active" to="/memebrs">Team Members</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/new-meeting">  Add Members</NavLink>
                    </li> */}
                      </ul>
                    </li>
                  </ul>
                  <div className="copyright">
                    <a href="https://fiill.pages.dev/">@ <span className="hide-mobile">fiill</span></a>
                  </div>
                </nav>
                <Route exact path="/new-meeting">
                  {!user ? <Redirect to="/login" /> : <NewMeeting uData={data} />}
                </Route>
                <Route exact path="/meetings/:id">
                  {!user ? <Redirect to="/login" /> : <MeetingReport uData={data} />}
                </Route>
                <Route exact path="/meetings">
                  {!user ? <Redirect to="/login" /> : <Meetings data={data} />}
                </Route>
                <Route exact path="/recommendations">
                  {!user ? <Redirect to="/login" /> : <Recommendations data={data} />}
                </Route>
                <Route exact path="/teams">
                  {!user ? <Redirect to="/login" /> : <Teams data={data} />}
                </Route>
                <Route exact path="/team/:id">
                  {!user ? <Redirect to="/login" /> : <Team uData={data} />}
                </Route>
                <Route exact path="/profile">
                  {!user ? <Redirect to="/login" /> : <Profile data={data} />}
                </Route>
                <Route exact path={["/", "/dashboard"]}>
                  {!user ? <Redirect to="/login" /> : <Dashboard data={data} />}
                </Route>
              </div>
            </Switch>
        }

      </div>
    </Router>
  );
}


