import { __URL__, __API_KEY_PADDING__ } from "./_env"

const getToken = (): string => `${__API_KEY_PADDING__}-${(Math.random() + 1).toString(36).substring(2)}-`;

export const callAi = async (data: { [key: string]: string }): Promise<Response | { error: boolean, innerError: unknown }> => {
  data['token'] = getToken();
  return await API.post('ai', data)
}



export const API = {
  async post(url: string, body: { [key: string]: string }, headers?: HeadersInit):
    Promise<Response | { error: boolean, innerError: unknown }> {
    url = __URL__[url]
    headers = headers || { 'Content-Type': 'application/json' }
    try {
      const res = await fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers,
      })
      return res.json();
    } catch (innerError) {
      return { error: true, innerError };
    }
  },

  async get(url: string, headers?: HeadersInit):
    Promise<Response | { error: boolean, innerError: unknown }> {
    url = __URL__[url]
    headers = headers || { 'Content-Type': 'application/json' }
    try {
      const res = await fetch(url, {
        method: "get",
        headers,
      })
      return res.json();
    } catch (innerError) {
      return { error: true, innerError };
    }
  }
}