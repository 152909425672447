import React from "react";
import logoG from '../../img/logo-g.svg'
import loginBg from '../../img/login-bg.jpg'

export class LoginLeft extends React.Component{
  render() {
    return <div className="login-left hide-mobile">
      <div className="logo grid-small">
        <a href="/">
          <img src={logoG} alt="Logo Fiill" />
        </a>
      </div>
      <h1 className="hide-mobile">
        Speak up,<br /> <span>Innovate</span>
      </h1>
      <img src={loginBg} alt="Fiill login" className="login-bg hide-mobile" />
    </div>;
  }
}