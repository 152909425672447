
import React from "react";
import { Link } from "react-router-dom";
import Header from './parts/header';
import '../styles/table.sass';
import FlashMessage from "./parts/messages";
import { save } from "../utils/fb";
import Loading from "./parts/loading";


export default class Profile extends React.Component<{ data: any }, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      deleted: this.props.data.deleted,
      data: this.props.data,
      showLoading: false,
      message: {
        show: false,
        content: '',
        status: ''
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event: any) {
    this.setState(
      {
        ...this.state, data: {
          ...this.state.data,
          [event.target.name]: event.target.value
        }
      }
    );

  }

  async handleSubmit(event: any) {
    this.setState({ showLoading: true });
    event.preventDefault();

    await save(this.state.data);

    const uData = this.props.data;
    uData.name = this.state.data.name;
    uData.company = this.state.data.company;
    uData.companySize = this.state.data.companySize;
    
    return this.setState(
      {
        ...this.state,
        showLoading: false,
        message: {
          status: 'success',
          show: true,
          content: "Your profile has been updated successfully!"
        }
      });
  }

  delete() {
    if (window.confirm('Are you sure you want to delete your account?')) {
      save(
        {
          ...this.props.data,
          deleted: true,
          deletedAt: new Date().toISOString()
        }
      );
      this.setState({ deleted: true })
    }
  }

  render() {
    return <div className="dashboard">
      {this.state.deleted &&
        <div className="pop-up show">
          <div className="inner-pop-up block">
            <h2 className="">Your account is set for deletion soon!!</h2>
            <p>You have request for your account to be deleted, our team will process your request in few days </p>
          </div>
        </div>}

      <Header data={{ title: 'Profile', uData: this.props.data }} />
      <FlashMessage m={this.state.message} />
      <div className="blocks">
        <div className="block">
          <div className="grid title">
            <h2>{this.state.data.name}</h2>
            <Link to="#" className="btn link link-danger small"
              onClick={() => this.delete()}>
              <span>Delete profile</span>
            </Link>
          </div>

          <section className="login">
            <FlashMessage m={this.state.message} />
            <div className="container grid">
              <div className="form">
                <Loading show={this.state.showLoading} />
                <h3>Welcome to Fiill</h3>
                <p>Please enter you information to create your profile</p>
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="name" className="name">
                    <span>Name</span>
                    <input type="text" name="name" required
                      placeholder="Enter your full name."
                      value={this.state.data.name} onChange={this.handleChange} />
                  </label>
                  <label htmlFor="email" className="email">
                    <span>Email address</span>
                    <input type="email" name="email" required disabled={true}
                      placeholder="Enter your email address."
                      value={this.state.data.email} onChange={this.handleChange} />
                  </label>
                  <label htmlFor="company" className="company">
                    <span>Company</span>
                    <input type="text" name="company" required
                      placeholder="Enter your company name."
                      value={this.state.data.company} onChange={this.handleChange} />
                  </label>
                  <label htmlFor="companySize" className="companySize">
                    <span>Company size</span>
                    <select name="companySize" id="companySize" required
                      value={this.state.data.companySize}
                      onChange={this.handleChange} defaultValue={''}>
                      <option disabled value=""> Select</option>
                      <option value="1-10">1-10</option>
                      <option value="11-100">11-100</option>
                      <option value="101-500">101-500</option>
                      <option value="+500">+500</option>
                    </select>
                  </label>

                  <input type="submit" value="Register" className="btn primary" />
                </form>
                <p className="register center">
                  <span>
                    You already have an account? &nbsp;
                  </span>
                  <Link className="btn link-primary small" to="/login">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </section>





          <Link to="/change-password" className="btn link-secondary small" >
            <span> *** Change your password</span>
          </Link>
        </div>

      </div>
    </div >;
  }
}
