import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';


export default class ChartLine extends PureComponent<{ data: any }> {
  render() {
    return (
      <div>
        {
          this.props.data ? <ResponsiveContainer width="100%" height="200%">
            <LineChart
              width={500}
              height={500}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Legend width={100} wrapperStyle={{ top: 0, right: 0, lineHeight: '40px' }} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="score" stroke="#3751FF" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="diversity" stroke="#27AE60" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="questions" stroke="#FEC400" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="time" stroke="#363740" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer> : ''
        }
      </div>

    );
  }
}


