import React from "react";
import Header from './parts/header'
import { nanoid } from 'nanoid'
import { save } from "../utils/fb";
import Loading from "./parts/loading";
import FlashMessage from './parts/messages'
import '../styles/form.sass'
import { Link } from "react-router-dom";
import editIcon from '../img/edit-solid.svg'

interface state {
  data: {
    // @ts-ignore we need to build this interface
    [key: string]: string | any,
    members:
    { [key: string]: string | any, }[]
  }
  showLoading?: boolean;
  message?: {
    show?: boolean;
    content: string;
    status?: string;
  }
}

export default class Team extends React.Component<{ uData: any }, state> {

  private teamIndex: number;
  private id: string
  constructor(props: any) {
    super(props);

    this.id = window.location.pathname.split('/').slice(-1)[0];
    const team = this.id !== 'new' ? this.props.uData.teams.find((i: any) => i.id === this.id) : null;
    this.teamIndex = this.id !== 'new' ? this.props.uData.teams.findIndex((i: any) => i.id === this.id) : this.props.uData.teams.length;

    this.state = {
      data: team ? { ...team } : {
        id: nanoid(),
        members: [],
        leader: {
          name: '',
          title: '',
        },
        name: '',
        description: '',
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.handelAddMember = this.handelAddMember.bind(this);
    this.expand = this.expand.bind(this);
  }

  expand(index: number) {
    const members = this.state.data.members;
    members[index].expanded = !this.state.data.members[index].expanded
    this.setState({
      data: {
        ...this.state.data,
        members
      }
    })
  }

  handleChange(e: any) {
    const name: string = e.target.name;
    const value = e.target.value;
    let data = this.state.data;

    if (name.includes('[')) {
      const [n, i] = name.split('[')
      const members = this.state.data.members
      members[parseInt(i)][n] = value;
      data = { ...this.state.data, members }
    } else {
      data = { ...this.state.data, [name]: value }
    }

    this.setState({ data })
    this.save()
  }

  handelAddMember() {
    this.setState({
      data: {
        ...this.state.data,
        members: [{
          id: nanoid(),
          firstName: '',
          lastName: '',
          email: '',
          job: '',
          role: '',
          hired: '',
          expanded: true
        }, ...this.state.data.members]
      }
    })
  }

  async deleteMember(id: string) {
    await this.setState({
      data: {
        ...this.state.data, members: this.state.data.members.filter((i: any) => id !== i.id)
      }
    });
    this.save()
  }

  async delete() {
    window.confirm('Are you sure you want to delete this team?') && this.props.uData.teams.splice(this.teamIndex, 1)
    save(this.props.uData);
    this.setState({
      data: {
        id: nanoid(),
        members: [],
        name: '',
        description: '',
        leaderName: '',
        leaderTitle: '',
      },
      message: {
        show: true,
        content: 'Team deleted',
        status: 'success'
      }
    })
  }

  save(e?: React.SyntheticEvent, d = false) {
    const data = this.props.uData;
    d ? data.teams.splice(this.teamIndex, 1) : data.teams[this.teamIndex] = this.state.data
    save(data);

    if (e) {
      e.preventDefault();
      this.setState({
        ...this.state,
        message: {
          show: true,
          content: 'Team deleted',
          status: 'success'
        }
      })
    }
  }


  render() {
    return <div className="dashboard new-team">
      <Header data={{ title: this.teamIndex > -1 ? this.state.data.name : 'New team', uData: this.props.uData }} />
      <FlashMessage m={this.state.message} />
      <div className="blocks">
        <div className="block">
          <div className="grid title">
            <h2>Enter details of the team </h2>
            {this.id !== 'new' && <Link to="#" className="btn link link-danger small"
              onClick={() => this.delete()}>
              <span>Delete team</span>
            </Link>}
          </div>
          <form onSubmit={this.save}>
            <Loading show={this.state.showLoading} />
            <label htmlFor="team-name">
              <span>Team name</span>
              <input type="text" name="name" id="team-name"
                placeholder="Enter the team name"
                onChange={this.handleChange} required value={this.state.data.name} />

            </label>
            <label htmlFor="description">
              <span>Team description</span>
              <textarea name="description" id="description"
                onChange={this.handleChange}
                value={this.state.data.description}></textarea>
            </label>

            <div className="grid datetime">
              <label htmlFor="leaderName">
                <span>Team lader name</span>
                <input type="text" name="leaderName" id="leaderName"
                  onChange={this.handleChange}
                  value={this.state.data.leaderName} />
              </label>
              <label htmlFor="leaderTitle">
                <span>Team lader title</span>
                <input type="text" name="leaderTitle" id="leaderTitle"
                  onChange={this.handleChange}
                  value={this.state.data.leaderTitle} />
              </label>
            </div>

            <div className="block">
              <div className="grid-small title">
                <h2>Members</h2>
                <Link to="#" className="btn link-secondary small" onClick={this.handelAddMember}>
                  <span>Add a team member</span>
                </Link>
              </div>
              {
                this.state.data.members?.map((m: any, index: number) => {
                  return <div className={m.expanded ? "block" : "block folded"} key={index}>
                    <button type="button" className="btn primary btn-update" onClick={() => this.expand(index)}>
                      {!m.expanded ? <img src={editIcon} alt="" /> : 'X'}
                    </button>
                    <h4>{`#${this.state.data.members.length - index}`}</h4>
                    <div className="grid-small datetime">
                      <label htmlFor={`firstName[${index}]`}>
                        <span>First name</span>
                        <input type="text" name={`firstName[${index}]`} id={`firstName[${index}]`}
                          placeholder="Enter team member first name!"
                          onChange={this.handleChange} required
                          value={m.firstName} />
                      </label>
                      <label htmlFor={`lastName[${index}]`}>
                        <span>Last name</span>
                        <input type="text" name={`lastName[${index}]`} id={`lastName[${index}]`}
                          placeholder="Enter team member last name!"
                          onChange={this.handleChange} required
                          value={m.lastName} />
                      </label>
                    </div>
                    <div className="grid-small datetime">
                      <label htmlFor={`email[${index}]`}>
                        <span>Email</span>
                        <input type="email" name={`email[${index}]`} id={`email[${index}]`}
                          placeholder="Enter team member email address!"
                          onChange={this.handleChange} required
                          value={m.email} />
                      </label>
                      <label htmlFor={`job[${index}]`}>
                        <span>Job title</span>
                        <input type="text" name={`job[${index}]`} id={`job[${index}]`}
                          placeholder="Enter team member job title!"
                          onChange={this.handleChange} required
                          value={m.job} />
                      </label>
                    </div>
                    <div className="grid-small datetime">
                      <label htmlFor={`role[${index}]`}>
                        <span>Role</span>
                        <select name="role" id="role">
                          <option value="contributor">Individual contributor</option>
                          <option value="manager">Manager</option>
                        </select>
                      </label>
                      <label htmlFor={`hired[${index}]`}>
                        <span>Hiring date</span>
                        <input type="date" name={`hired[${index}]`} id={`hired[${index}]`}
                          placeholder="Enter team member Hiring date!"
                          onChange={this.handleChange} required
                          value={this.state.data.members[index].hired} />
                      </label>
                    </div>
                    <div className="grid title">
                      <Link to="#" className="btn link link-danger small"
                        onClick={() => this.deleteMember(this.state.data.members[index].id)}>
                        <span>Delete team member</span>
                      </Link>
                    </div>
                  </div>
                })
              }
            </div>
            <label htmlFor="">
              <button type="submit" className="btn primary">Save</button>
            </label>
          </form>
        </div>
      </div>
    </div >;
  }
}
