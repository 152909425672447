import React from "react";
import Header from './parts/header'
import ChartLine from './charts/line'
import { Link } from "react-router-dom";
import '../styles/dashboard.sass'
import Recommendations from "./recommendations";


export default class Dashboard extends React.Component<{ data: any }, any> {
  r: Recommendations;
  constructor(props: any) {
    super(props);
    this.r = new Recommendations({ data: this.props.data });
    this.state = {
      recommendations: this.r.getRecommendations(),
    }
  }

  render() {
    return <div className="dashboard">
      <Header data={{ title: 'Overview', uData: this.props.data }} />
      <div className="blocks">
        {
          this.props.data.meetings.length < 1 &&
          <div className="pop-up show">
            <div className="inner-pop-up block">
              <h2>You dont have any data yet!!</h2>
              <p>You have not add data about you team, please add a meeting  </p>
            </div>
          </div>
        }
        <div className="grid">
          <div className="block quarter center">
            <h2>Score</h2>
            <h3>{this.props.data.score}</h3>
            <h4>Average meeting psychological safety score.</h4>
          </div>
          <div className="block quarter center">
            <h2>Time on topic</h2>
            <h3>{this.props.data.timeOnTopic}</h3>
            <h4>Average pourcentage of time on topic in meetings.</h4>
          </div>
          <div className="block quarter center">
            <h2>Relevant questions</h2>
            <h3>{this.props.data.questions}</h3>
            <h4>Average Number of Relevant question by meeting.</h4>

          </div>
          <div className="block quarter center">
            <h2>Participation Score</h2>
            <h3>{this.props.data.participation}</h3>
            <h4>Pourcentage participants who speak during the meeting.</h4>
          </div>
          <div className="block">
            <div className="title">
              <h2>Meeting’s trends</h2>
              <h4>as of 25 May 2019, 09:41 PM</h4>
            </div>
            {this.props.data.trends.length > 0 && <ChartLine data={this.props.data.trends} />}

          </div>
          <div className="block half">
            <div className="title grid">
              <div>
                <h2>Recent meetings</h2>
                <h4>Today</h4>
              </div>
              <div className="legend">
                <Link className="btn link-secondary small more" to="/meetings"> See all</Link>
              </div>
            </div>
            <ul className="list">
              {
                this.props.data.meetings.map((m: any) => {
                  return <li key={m.id}>
                    <Link className="grid tr" to={`/meetings/${m.id}`} key={m.id}>
                      <span >{m.title}</span>
                      <span className="badge btn link-primary small">{m.score || m.status}</span>
                    </Link>
                  </li>
                })
              }
            </ul>
          </div>

          <div className="block half">
            <div className="title grid">
              <div>
                <h2>Recommendations</h2>
                <h4>Today</h4>
              </div>
              <div className="legend">
                <Link className="btn link-secondary small more" to="/recommendations"> See all</Link>
              </div>
            </div>
            <ul className="list recommendations">
              {
                this.state.recommendations.map((m: any, index: number) => {
                  return <li className={m.status} key={m.id}>
                    <Link className="grid tr" to={`/recommendations/${m.id}`} key={m.id}>
                      <span>{m.message}</span>
                      <label htmlFor={'checkbok-done-' + m.id} className="badge btn link-primary small"
                        onClick={(e) => e.stopPropagation()}
                      ><input type="checkbox" onChange={(e) => this.setState({ recommendations: this.r.recommendationDone(index, e) })}
                        checked={m.status === 'done'} id={'checkbok-done-' + m.id} />
                      </label>
                    </Link>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>

    </div>;
  }
}
