import React from "react";
import Header from './parts/header'
import ChartLine from './charts/line'
import { Link } from "react-router-dom";
import '../styles/dashboard.sass'

interface state {
  data?: any
}

export default class MeetingReport extends React.Component<{ uData: any }, state> {
  render() {
    const id = window.location.pathname.split('/').slice(-1)[0];
    const meeting = this.props.uData.meetings.find((i: any) => i.id === id);
    return <div className="dashboard">
      <Header data={{ title: 'Meeting Report | ' + this.props.uData.title, uData: this.props.uData }} />
      <div className="blocks">
        {
          meeting.status === 'processing' &&
          <div className="pop-up show">
            <div className="inner-pop-up block">
              <h2>Processing in progress!!</h2>
              <p>Your meeting is been analyse we will notify you when the report id ready.  </p>
            </div>
          </div>
        }
        <div className="grid">
          <div className="block quarter center">
            <h2>Participation Score</h2>
            <h3>{meeting.participationScore}</h3>
          </div>
          <div className="block quarter center">
            <h2>Time on topic</h2>
            <h3>{meeting.timeOnTopic}</h3>
          </div>
          <div className="block quarter center">
            <h2>Relevant questions</h2>
            <h3>{meeting.questionsCount}</h3>
          </div>
          <div className="block quarter center">
            <h2>Score</h2>
            <h3>{meeting.score}</h3>
          </div>
          <div className="block">
            <div className="title">
              <h2>Meeting’s trends</h2>
              <h4>as of 25 May 2019, 09:41 PM</h4>
            </div>

            <ChartLine data={meeting.timeLine} />
          </div>
          <div className="block half">
            <div className="title grid">
              <div>
                <h2>Meeting questions</h2>
                <h4>Today</h4>
              </div>
              <div className="legend">
                <Link to={`/questions/${meeting.id}`} className="btn link-secondary small more">
                  See all
                </Link>
              </div>
            </div>
            <ul className="list">
              {

                this.props.uData.meetings?.map((m: any) => {
                  return <li key={m.id}>
                    <Link className="grid tr" to={`/meetings/${m.id}`} key={m.id}>
                      <span >{m.title}</span>
                      <span className="badge btn link-primary small">{m.score || m.status}</span>
                    </Link>
                  </li>
                })
              }
            </ul>
          </div>
          <div className="block half">
            <div className="title grid">
              <div>
                <h2>Meeting recommendations</h2>
                <h4>Today</h4>
              </div>
              <div className="legend">
                <Link to={`/recommendations/${meeting.id}`} className="btn link-secondary small more">
                  See all
                </Link>
              </div>
            </div>
            <ul className="list">
              {
                meeting.recommendations?.map((m: any) => {
                  return <li key={m.id}>
                    <Link className="grid tr" to={`/recommendation/${m.id}`} key={m.id}>
                      <span >{m.message}</span>
                      <span className="badge btn link-primary small">{m.score || m.status}</span>
                    </Link>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>;
  }
}
