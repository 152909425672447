import { auth } from "../utils/fb";
import React from "react";
import { signInWithEmailAndPassword } from "@firebase/auth";
import FlashMessage from "./parts/messages";
import { LoginLeft } from "./parts";
import Loading from "./parts/loading";
import { Link } from "react-router-dom";

interface state {
  email: string;
  password: string;
  message?: {
    show?: boolean,
    content: string
  }
  showLoading: boolean

}

export default class Login extends React.Component<unknown, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: any) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  async handleSubmit(e: any) {
    this.setState({ showLoading: true });

    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, this.state.email, this.state.password);
    } catch (e: any) {
      this.setState({ ...this.state, message: { show: true, content: e.code } });
      this.setState({ showLoading: false });
    }
    this.setState({ showLoading: false });
  }

  
  render() {
    return <section className="login">
      <FlashMessage m={this.state.message} />
      <div className="container grid">
        <LoginLeft />
        <div className="form">
          <Loading show={this.state.showLoading} />
          <h3>Welcome to Fiill</h3>
          <p>Please enter you email address and password login!</p>
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="email" className="email">
              <span>Email address</span>
              <input type="email" id="email" name="email" placeholder="Enter your email address." required value={this.state.email} onChange={this.handleChange} />
            </label>

            <label htmlFor="password" className="password">
              <span>Password</span>
              <input type="password" id="password" name="password" placeholder="Enter your password" required value={this.state.password} onChange={this.handleChange} />
            </label>
            <div className="form-meta grid-small">
              <label htmlFor="remember-me" className="remember-me">
                <input type="checkbox" name="remember-me" id="remember-me" />
                <span>
                  remember-me
                </span>
              </label>
              <Link className="btn link-primary small" to="/change-password?" >
                Forgot password?
              </Link>
            </div>
            <input type="submit" id="submit" value="Login" className="btn primary" />
          </form>
          <p className="register center">
            <span>
              Don&quot;t have an account?&nbsp;
            </span>
            <Link className="btn link-primary small" to="/register">
              Register
            </Link>
          </p>
        </div>
      </div>
    </section>;
  }

}