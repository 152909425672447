import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { doc, DocumentData, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { Data } from '../models/data';
import { __TABLE__ } from './_env';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAX6mj1tZnuaWole6EgBLzoigZvn0xzAOY",
  authDomain: "fiill-ai.firebaseapp.com",
  databaseURL: "https://fiill-ai.firebaseio.com",
  projectId: "fiill-ai",
  storageBucket: "fiill-ai.appspot.com",
  messagingSenderId: "649863626413",
  appId: "1:649863626413:web:3f3a9f2cfb96bc3a7e1024",
  measurementId: "G-49JJLXRXWX"
});

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp)


export const logOut = async (): Promise<void> => {
  await signOut(auth);
  window.location.href = `/login`;
};

export const getUserData = async (uid: string): Promise<null | undefined | DocumentData> => {
  try {
    const ref = doc(db, __TABLE__, uid);
    const data = await getDoc(ref);
    return data.data();
    // @ts-ignore avoid ts(1196)
  } catch (e: FirestoreError) {
    console.log(e.code)
    console.log(e.message)
    logOut();
  }
  return null;
}

export const save = async ( data: Data): Promise<void> => {
  await setDoc(doc(db, __TABLE__, data.uid as string), data);
}
