import React from "react";
import { sleep } from "../../utils";

export default class CircularProgressBar extends React.Component<{ sqSize?: any, strokeWidth?: any, percentage?: any, className?: string }, { percentage: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      percentage: 0
    };
  }

  async componentDidMount() {
    setTimeout(async () => {
      for (let i = 0; i <= this.props.percentage; i++) {
        this.setState({ ...this.state, percentage: i })
        await sleep(10);
      }
    }, 500);
  }

  render() {
    const sqSize = this.props.sqSize || '65';
    const strokeWidth = this.props.strokeWidth || 3;

    const radius = (sqSize - strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * this.state.percentage / 100;

    return (
      <svg width={sqSize} height={sqSize} viewBox={viewBox} >
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`} />
        <circle
          className={this.props.className + " circle-progress"}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }} />
        <text
          className={this.props.className + " circle-text"}
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle">
          {`${this.state.percentage}%`}
        </text>
      </svg>
    );
  }
}

