import React from "react";
import { Link } from "react-router-dom";
import Header from './parts/header';
import '../styles/table.sass';
import { save } from "../utils/fb";
import { doSort } from "../utils";


export default class Recommendations extends React.Component<{ data: any }, any> {
  recommendations: any;

  constructor(props: any) {
    super(props);
    this.recommendations = this.getRecommendations();
    this.state = {
      recommendations: this.recommendations,
      showDone: false
    }
    this.filter = this.filter.bind(this);
  }

  getRecommendations() {
    const r: any = [];
    this.props.data.meetings.forEach((meeting: any) => {
      if (meeting.recommendations) {
        meeting.recommendations.forEach((recommendation: any) => {
          recommendation.meeting = {
            time: meeting.time,
            location: meeting.location,
            description: meeting.description,
            attendees: meeting.attendees
          };
          recommendation['meetingId'] = meeting.id
          recommendation['meetingDate'] = meeting.date
          recommendation['meetingTitle'] = meeting.title
          r.push(recommendation);
        })
      }
    });
    return r;
  }

  recommendationDone(index: number, e: any, status?: string) {
    e.stopPropagation()
    const rs = this.state.recommendations;
    rs[index].status = status ? status : rs[index].status === 'done' ? 'undone' : 'done';
    console.log(rs[index].status)
    this.setState({ recommendations: rs });
    const mIndex = this.props.data.meetings.findIndex((m: any) => m.id === rs[index].meetingId);
    const rIndex = this.props.data.meetings[mIndex].recommendations.findIndex((r: any) => r.id === rs[index].id);
    this.props.data.meetings[mIndex].recommendations[rIndex].status = rs[index].status;
    save(this.props.data);
    return rs;
  }

  showHideDone() {
    this.setState({ ...this.state, showDone: !this.state.showDone })
  }

  sort(by: string) {
    const rev = this.state.sortedBy === by ? true : false;
    this.recommendations = doSort(by, this.state.recommendations, rev);
    this.setState({ recommendations: this.recommendations, sortedBy: rev ? `${by}--revers` : by });
  }

  filter(event: any) {
    const recommendations = this.recommendations.filter((m: any) => {
      return m.message.toLowerCase().includes(event.target.value.toLowerCase());
    });
    this.setState({ ...this.state, showDone: true, recommendations });
  }


  render() {
    return <div className="dashboard">
      <Header data={{ title: 'Recommendations', uData: this.props.data }} />
      <div className="blocks">
        <div className="grid">
          <div className="block">
            <div className="title grid">
              <h2>All recommendations</h2>
              <div className="grid">
                <label htmlFor="filter-meeting" className="filter btn link-primary small">
                  <input type="text" name="filter" id="filter-meeting" className="filter-meeting"
                    onChange={this.filter} />
                  <span>Filter</span>
                </label>
                <Link to="#" onClick={() => this.showHideDone()} className="btn link-secondary small">
                  <span>
                    {this.state.showDone ? '[X] Hide done/dismissed' : '[X] Show done/dismissed'}
                  </span>
                </Link>
              </div>
            </div>
            <div className={this.state.showDone ? "table table-4 recommendations show-done" : "table table-4 recommendations"}>
              <div className="tr th grid">
                <div className="td" onClick={() => this.sort('message')}>Recommendation</div>
                <div className="td third" onClick={() => this.sort('meetingTitle')}>Meeting</div>
                <div className="td quarter" onClick={() => this.sort('status')}>Applied</div>
                <div className="td quarter" onClick={() => this.sort('status')}>Dismissed</div>
              </div>
              {
                this.state.recommendations.map((m: any, index: number) => {
                  return <Link className={m.status + " recommendation grid tr"} to='#' key={m.id}>
                    <div className="td">
                      {m.message}
                      <br />
                      <h4>{m.details}</h4>
                    </div>
                    <div className="td third">{m.meetingTitle}
                      <br />
                      <h4> {m.meetingDate}</h4>
                    </div>
                    <div className="td quarter">
                      <label htmlFor={'checkbok-done-' + m.id} className="badge primary"
                        onClick={(e) => e.stopPropagation()}
                      ><input type="checkbox" onChange={(e) => this.recommendationDone(index, e)}
                        checked={m.status === 'done'} id={'checkbok-done-' + m.id} />
                      </label>
                      {/* <h4>{m.status}</h4> */}
                    </div>
                    <div className="td quarter">
                      <label htmlFor={'checkbok-dismissed-' + m.id} className="badge secondary"
                        onClick={(e) => e.stopPropagation()}
                      ><input type="checkbox"
                        onChange={(e) => this.recommendationDone(index, e, m.status === 'dismissed' ? 'undone' : 'dismissed')}
                        checked={m.status === 'dismissed'} id={'checkbok-dismissed-' + m.id} />
                      </label>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
