import { auth } from "../utils/fb";
import React from "react";
import { sendPasswordResetEmail } from "@firebase/auth";
import FlashMessage, { MessageProps } from "./parts/messages";
import { LoginLeft } from "./parts";
import Loading from "./parts/loading";
import { Link } from "react-router-dom";

interface state {
  email: string;
  message?: MessageProps
  showLoading: boolean
}

export default class RecoverPassword extends React.Component<unknown, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      showLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: any) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  async handleSubmit(e: any) {
    this.setState({ showLoading: true });
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, this.state.email);

    } catch (err: any) {
      console.error(err);
      this.setState({ ...this.state, message: { show: true, content: err.message } });
      this.setState({ showLoading: false });
    }

    this.setState({ ...this.state, message: { show: true, content: "Password reset link sent!", status: 'success' } });
    this.setState({ showLoading: false });
  }

  render() {
    return <section className="login">
      <FlashMessage m={this.state.message} />
      <div className="container grid">
        <LoginLeft />
        <div className="form">
          <Loading show={this.state.showLoading} />
          <h3>Please enter your email address!</h3>

          <form onSubmit={this.handleSubmit}>
            <label htmlFor="email" className="email">
              <span>Email address</span>
              <input type="email" name="email" placeholder="Enter your email address."
                required value={this.state.email} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Get link to change your password" className="btn primary" />
          </form>
          <p className="register center">
            <Link className="btn link-primary small" to="/login">
              Login
            </Link>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
            <Link className="btn link-primary small" to="/register">
              Register
            </Link>
          </p>
        </div>
      </div>
    </section>;
  }

}