import React from "react";
import { Link } from "react-router-dom";
import Header from './parts/header';
import '../styles/table.sass';
import CircularProgressBar from "./parts/circularProgressBar";
import sortUpIcon from "../img/sort-amount-up-solid.svg";
import sortDownIcon from "../img/sort-amount-down-solid.svg";
import { doSort } from "../utils";


export default class Meetings extends React.Component<{ data: any }, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: this.props.data,
      sortedBy: 'date',
      hideDone: false
    };
    this.filter = this.filter.bind(this);
  }

  getScore(m: any) {
    const score = parseInt(m.score) || 0;
    const status = m.status || 'processed';
    if (status === 'analyzing') { return ['gray', status, score] }
    if (score < 25) { return ['red', status, score] }
    if (score < 50) { return ['yellow', status, score] }
    if (score < 75) { return ['blue', status, score] }
    return ['green', status, score]
  }

  sort(by: string) {
    const rev = this.state.sortedBy === by ? true : false;
    const newData = doSort(by, this.props.data.meetings, rev);
    this.setState({ data: { ...this.props.data, meetings: newData }, sortedBy: rev ? `${by}--revers` : by });
  }

  filter(event: any) {
    const newData = this.props.data.meetings.filter((m: any) => {
      return m.title.toLowerCase().includes(event.target.value.toLowerCase());
    });
    this.setState({ data: { ...this.props.data, meetings: newData } });
  }

  getSortIcon(by: string) {
    if (this.state.sortedBy === by) return <img className="sort-image" src={sortUpIcon} alt="sort up" />
    if (this.state.sortedBy === `${by}--revers`) return <img className="sort-image" src={sortDownIcon} alt="sort up" />
  }

  render() {
    return <div className="dashboard">
      <Header data={{ title: 'Meetings', uData: this.props.data }} />
      <div className="blocks">
        <div className="grid">
          <div className="block">
            <div className="title grid">
              <h2>All meetings</h2>
              <div className="grid">
                <label htmlFor="filter-meeting" className="filter btn link-primary small">
                  <input type="text" name="filter" id="filter-meeting" className="filter-meeting"
                    onChange={this.filter} />
                  <span>Filter</span>
                </label>
                <Link id="new-meeting-btn" to="/new-meeting" className="btn link-secondary small">
                  <span>New meeting</span>
                </Link>
              </div>
            </div>
            <div className="table table-4">
              <div className="tr th grid">
                <div className="td quarter" onClick={() => this.sort('score')}>{this.getSortIcon('score')}Score/Status</div>
                <div className="td" onClick={() => this.sort('title')}>{this.getSortIcon('title')}Title</div>
                <div className="td third" onClick={() => this.sort('participants')}>{this.getSortIcon('participants')}Participants</div>
                <div className="td quarter active" onClick={() => this.sort('date')}>{this.getSortIcon('date')}Date</div>
              </div>
              {
                Object.keys(this.state.data.meetings).map((k: string) => {
                  const m = this.state.data.meetings[k];
                  const ss = this.getScore(m)
                  return <Link className="grid tr" to={`/meetings/${m.id}`} key={m.id}>
                    <div className="td quarter improvement">
                      <CircularProgressBar className={ss[0]} percentage={ss[2]} />
                    </div>
                    <div className="td">
                      {m.title}
                      <br />
                      <h4>Submitted 1 day ago</h4>
                    </div>
                    <div className="td third">{m.participants}
                      <br />
                      <h4>5 participants</h4>
                    </div>
                    <div className="td quarter">
                      {m.date}
                      <br />
                      <h4>10 Am</h4>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
