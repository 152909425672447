import { auth, save } from "../utils/fb";

import React from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, User, UserInfo } from "firebase/auth";

import Loading from "./parts/loading";
import FlashMessage from './parts/messages';
import { LoginLeft } from './parts';
import { Link } from "react-router-dom";

interface state {
  name?: string;
  email?: string;
  company?: string;
  companySize?: string;
  password?: string;
  message?: {
    show?: boolean,
    content: string,
    status?: string
  }
  showLoading?: boolean;
  uid?: string;
  meetings?: []
  teams: [],
  notifications: [],
  trends: []
}

export default class Register extends React.Component<any, state> {
  user: User | null;
  constructor(props: any) {
    super(props);
    this.user = auth.currentUser;
    this.state = {
      name: '',
      email: '',
      company: '',
      companySize: '',
      password: '',
      teams: [],
      meetings: [],
      notifications: [],
      trends: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  async register() {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, this.state.email!, this.state.password!);
      const user = userCredential.user;
      return user;
    } catch (e: any) {
      return e;
    }
  }

  async handleSubmit(event: any) {
    this.setState({ showLoading: true });
    event.preventDefault();
    console.log(this.state.companySize);

    if (!this.user) {
      this.user = await this.register();
      if (!this.user || !this.user.uid) {
        this.setState({ showLoading: false });
        return this.setState(
          {
            ...this.state,
            message:
            {
              show: true, content: 'auth/email-already-in-use'
            }
          });
      }
      try {
        await signInWithEmailAndPassword(auth, this.state.email!, this.state.password!);
      } catch (e: any) {
        console.log(e);
        this.setState({ showLoading: false });
        return this.setState({ ...this.state, message: { show: true, content: e.code } });
      }
    }

    const { password, showLoading, ...data } = this.state;

    await save({
      ...data,
      uid: this.user.uid
    });

    this.setState(
      {
        ...this.state,
        showLoading: false,
        message: {
          status: 'success',
          show: true,
          content: "Your profile has been updated successfully!"
        }
      });
    return window.location.reload();
  }

  render() {
    return <section className="login">
      <FlashMessage m={this.state.message} />
      <div className="container grid">
        <LoginLeft />
        <div className="form">
          <Loading show={this.state.showLoading} />
          <h3>Welcome to Fiill</h3>
          <p>Please enter you information to create your profile</p>
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="name" className="name">
              <span>Name</span>
              <input type="text" name="name" placeholder="Enter your full name." required value={this.state.name} onChange={this.handleChange} />
            </label>
            <label htmlFor="email" className="email">
              <span>Email address</span>
              <input type="email" name="email" required
                placeholder="Enter your email address."
                value={this.state.email} onChange={this.handleChange} />
            </label>
            <label htmlFor="company" className="company">
              <span>Company</span>
              <input type="text" name="company" placeholder="Enter your company name." required value={this.state.company} onChange={this.handleChange} />
            </label>
            <label htmlFor="companySize" className="companySize">
              <span>Company size</span>
              <select name="companySize" id="companySize" required value={this.state.companySize}
                onChange={this.handleChange} defaultValue={''}>
                <option disabled value=""> Select</option>
                <option value="1-10">1-10</option>
                <option value="11-100">11-100</option>
                <option value="101-500">101-500</option>
                <option value="+500">+500</option>
              </select>
            </label>
            <label htmlFor="password" className="password">
              <span>Password</span>
              <input type="password" name="password" id="password" placeholder="Enter your password"
                value={this.state.password} onChange={this.handleChange}
                required={this.user ? false : true}
              />
            </label>
            <input type="submit" value="Register" className="btn primary" />
          </form>
          <p className="register center">
            <span>
              You already have an account? &nbsp;
            </span>
            <Link className="btn link-primary small" to="/login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </section>;
  }

}