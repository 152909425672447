import React from "react";
import { Link } from "react-router-dom";
import Header from './parts/header';
import '../styles/table.sass';
import CircularProgressBar from "./parts/circularProgressBar";
import { doSort } from "../utils";
import { Member, Team } from "../models/team";


interface TeamsProps {
  data: {
    teams: Array<Team>
  };
}

export default class Teams extends React.Component<TeamsProps,
  { sortedBy: string, data: { teams: Team[] } }> {
  constructor(props: TeamsProps | Readonly<TeamsProps>) {
    super(props);
    this.state = {
      data: this.props.data,
      sortedBy: 'name',
    };
    this.filter = this.filter.bind(this);
  }

  getScore(m: Team): [string, string, number] {
    const score = m.score || 0;
    const status = m.status || 'processed';
    if (status === 'analyzing') { return ['gray', status, score] }
    if (score < 25) { return ['red', status, score] }
    if (score < 50) { return ['yellow', status, score] }
    if (score < 75) { return ['blue', status, score] }
    return ['green', status, score]
  }

  sort(by: string): void {
    const rev = this.state.sortedBy === by ? true : false;
    const newData = doSort(by, this.props.data.teams, rev);
    this.setState({
      data: { ...this.props.data, teams: newData },
      sortedBy: rev ? `${by}--revers` : by
    });
  }

  filter(event: React.ChangeEvent<HTMLInputElement>): void {
    const newData = this.props.data.teams.filter((m: Team) => {
      return m.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    this.setState({ data: { ...this.props.data, teams: newData } });
  }

  render():React.ReactNode {
    return <div className="dashboard">
      <Header data={{ title: 'Teams', uData: this.props.data }} />
      <div className="blocks">
        <div className="grid">
          <div className="block">
            <div className="title grid">
              <h2>All teams</h2>
              <div className="grid">
                <label htmlFor="filter-meeting" className="filter btn link-primary small">
                  <input type="text" name="filter" id="filter-meeting" className="filter-meeting"
                    onChange={this.filter} placeholder="Filter by name" />
                  <span>Filter</span>
                </label>
                <Link to="/team/new" className="btn link-secondary small">
                  <span>New team</span>
                </Link>
              </div>
            </div>
            <div className="table table-4">
              <div className="tr th grid">
                <div className="td quarter" onClick={() => this.sort('score')}>Team score</div>
                <div className="td" onClick={() => this.sort('name')}>Title</div>
                <div className="td third" onClick={() => this.sort('leaderName')}>Leader</div>
                <div className="td third" onClick={() => this.sort('name')}>Members</div>
              </div>
              {
                this.state.data.teams?.map((t: Team) => {
                  const ss = this.getScore(t)
                  return <Link className="grid tr" to={`/team/${t.id}`} key={t.id}>
                    <div className="td quarter improvement">
                      <CircularProgressBar className={ss[0]} percentage={ss[2]} />
                    </div>
                    <div className="td">
                      {t.name}
                      <br />
                      <h4> {t.description}</h4>
                    </div>
                    <div className="td third">{t.leaderName}
                      <br />
                      <h4>{t.leaderTitle}</h4>
                    </div>
                    <div className="td third">{t.members.length} Memebrs
                      <br />
                      <h4>{t.members.map((i: Member) => `${i.firstName}, `)}</h4>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
