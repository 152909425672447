import React from "react";

export default class Loading extends React.Component<{ show?: boolean, home?: boolean }> {

  getClass() {
    let c = 'loading';
    c = this.props.show ? `${c} show` : c;
    c = this.props.home ? `${c} home` : c
    return c
  }

  render() {
    return <div className={this.getClass()} >
      {/* <div className="shape shape1">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape1.png'} alt="shape1" />
      </div>
      <div className="shape shape2">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape2.png'} alt="shape2" />
      </div>
      <div className="shape shape3">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape3.png'} alt="shape3" />
      </div>
      <div className="shape shape4">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape4.png'} alt="shape4" />
      </div>
      <div className="shape shape5">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape5.png'} alt="shape5" />
      </div>
      <div className="shape shape6">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape6.png'} alt="shape6" />
      </div>
      <div className="shape shape7">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape7.png'} alt="shape7" />
      </div>
      <div className="shape shape8">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape8.png'} alt="shape8" />
      </div>
      <div className="shape shape9">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape9.png'} alt="shape9" />
      </div>
      <div className="shape shape10 hide-mobile">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape10.png'} alt="shape10" />
      </div>
      <div className="shape shape13">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape13.png'} alt="shape13" />
      </div>
      <div className="shape shape14 hide-mobile">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape14.png'} alt="shape14" />
      </div>
      <div className="shape shape15">
        <img src={__STATICS_URL__ + '/assets/img/shapes/shape15.png'} alt="shape15" />
      </div> */}
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>;
  }
}
