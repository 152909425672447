import React from "react";

const MESSAGES: { [key: string]: string } = {
  'auth/email-already-in-use': 'An account with this email address address already exist, please try to login!',
  'auth/user-not-found': 'There is non user with this email address, click register to create an account!',
  'auth/wrong-password': 'Password incorrect!',
  'fiill/test-message': 'This message is for testing..............'
}



export interface MessageProps {
  show?: boolean,
  content: string,
  status?: string
}

export default class FlashMessage extends React.Component<{ m?: MessageProps }> {
  render() {
    if (this.props.m) {
      const css = `messages ${this.props.m.show || 'hide'} ${this.props.m.status || 'error'}`
      return <span className={css}>{MESSAGES[this.props.m.content] || this.props.m.content}</span>
    }
    return '';
  }
}